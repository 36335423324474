import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerDashboardService {
  private agents$ = new BehaviorSubject([]);

  public getAgent$ = this.agents$.asObservable();

  private fetchAgreementData$ = new BehaviorSubject(false);

  public shouldViewFetchAgreement$ = this.fetchAgreementData$.asObservable();

  private fetchFeedbackData$ = new BehaviorSubject(false);

  public shouldViewFetchFeedback$ = this.fetchFeedbackData$.asObservable();

  constructor() { }

  setAgents(data: any) {
    this.agents$.next(data);
  }

  setFetchAgreement(data){
    this.fetchAgreementData$.next(data);
  }

  setFetchFeedback(data){
    this.fetchFeedbackData$.next(data);
  }
}
